<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div
        class="sidebar-shop"
        :class="{'show': mqShallShowLeftSidebar}"
      >
        <b-row>
          <b-col cols="12">
            <h6 class="filter-heading d-none d-lg-block">
              {{ $t('Filters') }}
            </h6>
          </b-col>
        </b-row>

        <!-- Filters' Card -->
        <b-card>

          <b-card-title class="mb-1">
            {{ $t('Mentoring language') }}
          </b-card-title>
          <b-card-text class="mb-2">
            <b-form-group class="filters-options-group">
              <b-form-radio
                v-for="(localeInfo, index) in locales"
                :key="index"
                v-model="selectedMentoringLanguage"
                name="mentoringLanguage"
                :value="localeInfo.locale"
              >
                {{ localeInfo.name }}
              </b-form-radio>
            </b-form-group>
          </b-card-text>

          <b-card-title class="mb-1">
            {{ $t('Attribute') }}
          </b-card-title>
          <b-card-text class="mb-2">
            <b-form-group class="filters-options-group">
              <b-form-radio
                v-model="selectedAttribute"
                name="attribute"
                value="mentors"
              >
                Mentors
              </b-form-radio>
              <b-form-radio
                v-model="selectedAttribute"
                name="attribute"
                value="coaches"
              >
                Coaches
              </b-form-radio>
            </b-form-group>
          </b-card-text>

          <b-card-title class="mb-1">
            {{ $t('Availability') }}
          </b-card-title>
          <b-card-text class="mb-2">
            <b-form-checkbox
              v-model="isAvailable"
              :value="true"
            >
              {{ $t('Available now') }}
            </b-form-checkbox>
          </b-card-text>

          <b-card-title class="mb-1">
            {{ $t('Country') }}
          </b-card-title>
          <b-card-text class="mb-2">
            <b-form-select
              id="country"
              v-model="selectedCountry"
              :options="countryOptions"
            />
          </b-card-text>

          <template v-if="countyFilterVisible">
            <b-card-title class="mb-1">
              {{ $t('County') }}
            </b-card-title>
            <b-card-text class="mb-2">
              <b-form-select
                id="county"
                v-model="selectedCounty"
                :options="countyOptions"
              />
            </b-card-text>
          </template>

          <b-card-title class="mb-1">
            {{ $t('Years of experience') }}<br>
            <a
              class="clear"
              href="javascript:void(0)"
              @click="clearYearsFilter"
            >{{ $t('clear') }}</a>
          </b-card-title>
          <b-card-text class="mb-2">
            <b-form-group class="filters-options-group">
              <b-form-radio
                v-model="yearsOfExperience"
                value="3"
              >
                3+ {{ $t('years') }}
              </b-form-radio>
              <b-form-radio
                v-model="yearsOfExperience"
                value="5"
              >
                5+ {{ $t('years') }}
              </b-form-radio>
              <b-form-radio
                v-model="yearsOfExperience"
                value="7"
              >
                7+ {{ $t('years') }}
              </b-form-radio>
              <b-form-radio
                v-model="yearsOfExperience"
                value="10"
              >
                10+ {{ $t('years') }}
              </b-form-radio>
            </b-form-group>
          </b-card-text>

          <b-card-title class="mb-1">
            {{ $t('FieldOfSupport') }}<br>
            <a
              class="clear"
              href="javascript:void(0)"
              @click="clearFieldofSupportFilter"
            >{{ $t('clear') }}</a>
          </b-card-title>
          <b-card-text class="mb-2">
            <b-form-group class="filters-options-group">
              <b-form-checkbox
                v-for="field in fieldsOfSupport"
                :key="field.id"
                v-model="selectedFieldsOfSupport"
                class="text-break"
                :value="field.id"
              >
                {{ $t(field.name) }}
              </b-form-checkbox>
            </b-form-group>
          </b-card-text>

          <b-card-title class="mb-1">
            {{ $t('Industry') }}<br>
            <a
              class="clear"
              href="javascript:void(0)"
              @click="clearIndustryFilter"
            >{{ $t('clear') }}</a>
          </b-card-title>
          <b-card-text class="mb-2">
            <b-form-group class="filters-options-group">
              <b-form-checkbox
                v-for="industry in industries"
                :key="industry.id"
                v-model="selectedIndustries"
                class="text-break"
                :value="industry.id"
              >
                {{ $t(industry.name) }}
              </b-form-checkbox>
            </b-form-group>
          </b-card-text>

        </b-card>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />

    <language-filter-modal ref="language-filter-modal" />
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardText,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormRadio,
  BFormSelect,
  BRow,
} from 'bootstrap-vue'
import {
  useIndustryRepository,
  useFieldOfSupportRepository,
  useCountryRepository,
  useCountyRepository,
  useLocales,
} from '@mentoring-platform/composables'
import { mentorFilters } from '@mentoring-platform/constants'
import LanguageFilterModal from '@mentoring-platform/views/components/blocks/Mentors/LanguageFilterModal.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormRadio,
    BFormSelect,
    BRow,
    LanguageFilterModal,
  },
  props: {
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedMentoringLanguage: null,
      selectedAttribute: null,
      selectedCountry: null,
      selectedCounty: null,
      selectedIndustries: [],
      selectedFieldsOfSupport: [],
      isAvailable: null,
      yearsOfExperience: null,
    }
  },
  computed: {
    countyFilterVisible() {
      const visible = this.selectedCountry === this.countryIdOfGreece
      if (!visible) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedCounty = null
      }
      return visible
    },
    currentLocale() {
      return this.$i18n.locale
    },
  },
  // watch: {
  //   currentLocale() {
  //     if (this.needToShowLanguageFilterNotification()) {
  //       this.showLanguageFilterNotification()
  //     }
  //   },
  // },
  watch: {
    currentLocale() {
      if (this.needToShowLanguageFilterNotification()) {
        this.showLanguageFilterNotification()
      }
    },
    selectedMentoringLanguage() {
      this.applyFilters()
    },
    selectedAttribute() {
      this.applyFilters()
    },
    selectedCountry() {
      this.applyFilters()
    },
    selectedCounty() {
      this.applyFilters()
    },
    selectedIndustries() {
      this.applyFilters()
    },
    selectedFieldsOfSupport() {
      this.applyFilters()
    },
    isAvailable() {
      this.applyFilters()
    },
    yearsOfExperience() {
      this.applyFilters()
    },
  },
  created() {
    if (this.$route.query.f !== undefined) {
      String(this.$route.query.f).split('|').forEach(filterGroup => {
        let filterName
        let filterValues
        if (filterGroup.includes('..')) {
          // eslint-disable-next-line no-param-reassign
          filterGroup = filterGroup.split('..')
          // eslint-disable-next-line prefer-destructuring
          filterName = filterGroup[0]
          if (filterGroup[1] !== undefined) {
            filterValues = filterGroup[1].split(',')
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          filterGroup = filterGroup.split('.')
          // eslint-disable-next-line prefer-destructuring
          filterName = filterGroup[0]
          if (filterGroup[1] !== undefined) {
            // eslint-disable-next-line prefer-destructuring
            filterValues = filterGroup[1]
          }
        }

        if (filterName === mentorFilters.MENTOR_FILTER_SUPPORTS_GREEK) {
          this.selectedMentoringLanguage = 'gr'
        }
        if (filterName === mentorFilters.MENTOR_FILTER_SUPPORTS_ENGLISH) {
          this.selectedMentoringLanguage = 'en'
        }
        if (filterName === mentorFilters.MENTOR_FILTER_IS_MENTOR) {
          this.selectedAttribute = 'mentors'
        }
        if (filterName === mentorFilters.MENTOR_FILTER_IS_COACH) {
          this.selectedAttribute = 'coaches'
        }
        if (filterName === mentorFilters.MENTOR_FILTER_COUNTRY) {
          this.selectedCountry = Number(filterValues)
        }
        if (filterName === mentorFilters.MENTOR_FILTER_COUNTY) {
          this.selectedCounty = Number(filterValues)
        }
        if (filterName === mentorFilters.MENTOR_FILTER_INDUSTRY) {
          this.selectedIndustries = filterValues.map(Number)
        }
        if (filterName === mentorFilters.MENTOR_FILTER_FIELD_OF_SUPPORT) {
          this.selectedFieldsOfSupport = filterValues.map(Number)
        }
        if (filterName === mentorFilters.MENTOR_FILTER_IS_AVAILABLE) {
          this.isAvailable = true
        }
        if (filterName === mentorFilters.MENTOR_FILTER_YEARS_OF_EXPERIENCE) {
          this.yearsOfExperience = filterValues
        }
      })
      this.applyFilters()
    } else {
      if (this.$route.query.view === 'coaches') {
        this.selectedAttribute = 'coaches'
      } else {
        this.selectedAttribute = 'mentors'
      }
      if (this.$route.query.view) {
        this.$router.push({ query: {} })
      }
    }
  },
  mounted() {
    if (this.needToShowLanguageFilterNotification()) {
      this.showLanguageFilterNotification()
    }
  },
  methods: {
    collectFilters() {
      const filters = []
      if (this.selectedMentoringLanguage === 'gr') {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_SUPPORTS_GREEK,
        })
      }
      if (this.selectedMentoringLanguage === 'en') {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_SUPPORTS_ENGLISH,
        })
      }
      if (this.selectedAttribute === 'mentors') {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_IS_MENTOR,
        })
        this.$route.meta.pageHeader.title = 'Our mentors'
      }
      if (this.selectedAttribute === 'coaches') {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_IS_COACH,
        })
        this.$route.meta.pageHeader.title = 'Our coaches'
      }
      if (this.selectedCountry !== null) {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_COUNTRY,
          value: this.selectedCountry,
        })
      }
      if (this.selectedCounty !== null) {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_COUNTY,
          value: this.selectedCounty,
        })
      }
      if (this.selectedIndustries.length > 0) {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_INDUSTRY,
          values: this.selectedIndustries,
        })
      }
      if (this.selectedFieldsOfSupport.length > 0) {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_FIELD_OF_SUPPORT,
          values: this.selectedFieldsOfSupport,
        })
      }
      if (this.isAvailable === true) {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_IS_AVAILABLE,
        })
      }
      if (this.yearsOfExperience !== null) {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_YEARS_OF_EXPERIENCE,
          value: this.yearsOfExperience,
        })
      }
      return filters
    },
    applyFilters() {
      this.$emit('mentors-list-apply-filters', this.collectFilters())
    },
    clearYearsFilter() {
      this.yearsOfExperience = null
    },
    clearFieldofSupportFilter() {
      this.selectedFieldsOfSupport = []
    },
    clearIndustryFilter() {
      this.selectedIndustries = []
    },
    needToShowLanguageFilterNotification() {
      return this.currentLocale !== 'gr' && !this.selectedMentoringLanguage
    },
    showLanguageFilterNotification() {
      this.$refs['language-filter-modal'].show()
    },
  },
  setup() {
    const { industries, getIndustries } = useIndustryRepository()
    const { fieldsOfSupport, getFieldsOfSupport } = useFieldOfSupportRepository()
    const { countryOptions, getCountries, countryIdOfGreece } = useCountryRepository()
    const { countyOptions, getCounties } = useCountyRepository()
    const { locales } = useLocales()
    getIndustries()
    getFieldsOfSupport()
    getCountries()
    getCounties()

    return {
      industries,
      fieldsOfSupport,
      countryOptions,
      countryIdOfGreece,
      countyOptions,
      locales,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.filters-options-group
{
    .custom-control {
    margin-bottom: 0.35rem;
  }
}
.card .card-title {
  font-weight: 600;
  font-size: 16px;
}
.filter-heading {
  font-size: 16px;
}

.clear {
  font-size: 12px;
}
</style>
