<template>
  <div style="height: inherit">

    <!-- Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ finalMentorsCount }} {{ isCoachesPage ? $t('coaches') : $t('mentors') }}
              </div>
            </div>
            <div class="view-options d-flex" />
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="searchValue"
              :placeholder="$t('Search mentors')"
              class="search-product"
              debounce="400"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!-- Mentors -->
    <section :class="itemView">
      <mentor-tile
        v-for="(mentor, index) in visibleMentors"
        :key="index"
        :mentor="mentor"
      />
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="finalMentorsCount"
            :per-page="mentorsPerPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
            @change="handlePageChange"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Help -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-card>
            <div class="text-center my-2">
              <b-card-title>
                {{ $t('Do not you find the mentor you want?') }}
              </b-card-title>
              <b-card-text>
                {{ $t('You can ask us to suggest you a mentor') }} {{ $t('Send us an email at') }}
                <b-link href="mailto:info@when.org.gr">
                  info@when.org.gr
                </b-link>
              </b-card-text>
              <!-- <b-button variant="outline-primary">
                {{ $t('I need help for mentoring') }}
              </b-button> -->
            </div>
          </b-card>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <mentor-filters
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @mentors-list-apply-filters="applyFilters"
      />
    </portal>
  </div>
</template>

<script>
import {
  // BButton,
  BCard,
  BCardText,
  BCardTitle,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import MentorFilters from '@mentoring-platform/views/components/blocks/Mentors/MentorFilters.vue'
import MentorTile from '@mentoring-platform/views/components/blocks/Mentors/MentorTile.vue'
import { useMentorRepository, useMentorFiltering, useMentorFullTextSearch } from '@mentoring-platform/composables'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ScrollToTopMixin from '@mentoring-platform/mixins/ScrollToTopMixin'

export default {
  components: {
    // BButton,
    BCard,
    BCardText,
    BCardTitle,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BLink,
    BPagination,
    BRow,
    MentorFilters,
    MentorTile,
  },
  mixins: [
    ScrollToTopMixin,
  ],
  data() {
    return {
      itemView: 'grid-view',
      currentPage: 1,
      mentorsPerPage: 12,
    }
  },
  computed: {
    mentorsCount() {
      return this.mentors.length
    },
    finalMentorsCount() {
      return this.searchedMentors.length
    },
    visibleMentors() {
      const { searchedMentors } = this
      const start = (this.currentPage - 1) * this.mentorsPerPage
      return searchedMentors.slice(start, start + this.mentorsPerPage)
    },
    isCoachesPage() {
      return this.$route?.query?.f?.toString()?.includes('isCoach')
    },
  },
  methods: {
    async handlePageChange() {
      // we wait for a tick to pass because b-pagination gives focus to a specific element at this tick
      await this.$nextTick()
      this.scrollToTop()
    },
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const { mentors, getMentors } = useMentorRepository()
    const { applyFilters, filteredMentors } = useMentorFiltering()
    const { searchValue, searchedMentors } = useMentorFullTextSearch()
    getMentors()

    return {
      mentors,
      applyFilters,
      filteredMentors,
      searchValue,
      searchedMentors,
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";

.view-options {
  height: 38px;
}
</style>
